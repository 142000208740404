/**
 * A React hook that fires a callback whenever ResizeObserver detects a change to its size
 *
 * @param target A React ref created by `useRef()` or an HTML element
 * @param callback Invoked with a single `ResizeObserverEntry` any time
 *   the `target` resizes
 */
import { RefObject, useLayoutEffect, useState } from 'react'

function useResizeObserver<T extends HTMLElement>(
  target: RefObject<T> | T | null,
  callback: UseResizeObserverCallback,
) {
  const [prevWidth, setPrevWidth] = useState(0)
  const [prevHeight, setPrevHeight] = useState(0)
  useLayoutEffect(() => {
    if (!target) return () => {}

    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect

      const widthResized = width !== prevWidth
      const heightResized = height !== prevHeight
      if (widthResized || heightResized) {
        callback({
          resized: {
            width: widthResized,
            height: heightResized,
          },
          size: {
            width,
            height,
          },
        })
        widthResized && setPrevWidth(width)
        heightResized && setPrevHeight(height)
      }
    })

    resizeObserver.observe(target as HTMLElement)
    return () => resizeObserver.unobserve(target as HTMLElement)
  }, [target])
}

type UseResizeObserverCallback = (entry: {
  resized: {
    width?: boolean
    height?: boolean
  }
  size: {
    width?: number
    height?: number
  }
}) => void

export default useResizeObserver
