import clsx from 'clsx'
import { DOCS_LINK, LINKS, REST_API_LINK } from 'routes/constants'
import { useState, useEffect, HTMLAttributes } from 'react'
import NavLink from 'routes/components/Shared/Link/NavLink'
import { Link, LogoBlock } from 'routes/components/Shared'
import classes from './Footer.module.css'
import { FooterCTA } from '../../Features/FooterCTA/FooterCTA'

const footerComparisonsIds = [
  { id: 'merge', name: 'Merge.dev' },
  { id: 'paragon', name: 'Paragon' },
  { id: 'prismatic', name: 'Prismatic' },
  { id: 'apideck', name: 'Apideck' },
  { id: 'nango', name: 'Nango.dev' },
]

export function Footer({
  hideAdvertiseBlock = false,
}: {
  hideAdvertiseBlock?: boolean
}) {
  return (
    <>
      {!hideAdvertiseBlock && (
        <div className={'container'}>
          <FooterCTA />
        </div>
      )}

      <footer className={classes.footer}>
        <div className={clsx('container', classes.footerContainer)}>
          <FooterMenu />
          <FooterBottom />
        </div>
      </footer>
    </>
  )
}

function FooterMenu() {
  return (
    <div className={classes.navWrapper}>
      <Link className={classes.topLogo} href='/'>
        <img
          width={53}
          height={32}
          loading='lazy'
          src='/images/logo--white.svg'
          alt='integration.app logo'
        />
      </Link>
      <FooterCompanyList />
      <FooterProductList />
      <FooterComparisonsList />
      <FooterConnectorsList />
    </div>
  )
}

function FooterCompanyList() {
  const links = [
    { title: 'About us', href: LINKS.ABOUT_US },
    { title: 'Blog', href: LINKS.ARTICLES },
    { title: 'How we are different', href: LINKS.HOW_WE_ARE_DIFFERENT },
    { title: 'Pricing', href: LINKS.PRICING },
    { title: 'Documentation', href: DOCS_LINK },
    { title: 'API Reference', href: REST_API_LINK },
  ]

  return (
    <nav className={clsx(classes.navColumn, classes.navColumn__company)}>
      <h5 className={classes.groupHead}>Company</h5>

      <ul className={classes.nav}>
        {links.map(({ title, href }) => (
          <li key={title}>
            <NavLink
              href={href}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

function FooterProductList() {
  const links = [
    { title: 'Integration Engine', href: LINKS.INTEGRATION_ENGINE },
    { title: 'Connector Builder', href: LINKS.CONNECTOR_BUILDER },
    { title: 'Integration UX', href: LINKS.INTEGRATION_UX },
    { title: 'Universal Integrations', href: LINKS.UNIVERSAL_INTEGRATIONS },
    { title: 'How we are different', href: LINKS.HOW_WE_ARE_DIFFERENT },
    { title: 'How we use AI', href: LINKS.HOW_WE_USE_AI },
  ]

  return (
    <Details.Root
      closeOnMobile
      className={clsx(classes.navColumn, classes.navColumn__product)}
    >
      <Details.Header>Product</Details.Header>

      <ul className={classes.nav}>
        {links.map(({ title, href }) => (
          <li key={title}>
            <NavLink
              href={href}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </Details.Root>
  )
}

function FooterComparisonsList() {
  return (
    <Details.Root
      closeOnMobile
      className={clsx(classes.navColumn, classes.navColumn__comparison)}
    >
      <Details.Header>Comparisons</Details.Header>

      <ul className={classes.nav}>
        {footerComparisonsIds.map((item) => (
          <li key={item.id}>
            <NavLink
              href={`/alternatives/${item.id}`}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              {`${item.name} alternative`}
            </NavLink>
          </li>
        ))}
      </ul>
    </Details.Root>
  )
}

function FooterConnectorsList() {
  const categories = [
    {
      name: 'CRM',
    },
    {
      name: 'Project Management',
    },
    {
      name: 'Ticketing',
    },
    {
      name: 'Sales',
    },
    {
      name: 'Marketing Automation',
    },
    {
      name: 'Communication',
    },
    {
      name: 'Accounting',
    },
    {
      name: 'Customer Success',
    },
    {
      name: 'File Storage',
    },
    {
      name: 'ERP',
    },
    {
      name: 'ATS',
    },
    {
      name: 'E-Commerce',
    },
    {
      name: 'HR',
    },
    {
      name: 'Analytics',
    },
  ]

  return (
    <Details.Root closeOnMobile className={classes.navConnectors}>
      <Details.Header>Connectors</Details.Header>

      <ul className={classes.connectorsNav}>
        {categories.map((category) => (
          <li key={category.name}>
            <NavLink
              href={`${LINKS.INTEGRATIONS}?category=${category.name}`}
              className={classes.connectorsLink}
            >
              {category.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </Details.Root>
  )
}

function FooterBottom() {
  return (
    <div className={classes.footerBottom}>
      <div className={classes.footerBottomTop}>
        <LogoBlock className={classes.bottomLogo} />
        <div className={classes.copyrights}>
          © Copyrights {new Date().getFullYear()}{' '}
          <a href='https://integration.app'>Integration.app</a>
        </div>
      </div>
      <FooterLegalLinks />
    </div>
  )
}

function FooterLegalLinks() {
  return (
    <nav className={classes.legalLinks}>
      <li>
        <NavLink
          href={'/privacy-policy'}
          className={classes.link}
          activeClassName={classes.link__active}
        >
          Privacy Policy
        </NavLink>
      </li>
      <li>
        <NavLink
          href={'/terms-and-conditions'}
          className={classes.link}
          activeClassName={classes.link__active}
        >
          Terms and Conditions
        </NavLink>
      </li>
      <li>
        <a className={classes.link} href='mailto:hello@integration.app'>
          hello@integration.app
        </a>
      </li>
    </nav>
  )
}

function DetailsRoot({
  children,
  className,
  closeOnMobile,
  ...props
}: HTMLAttributes<HTMLDetailsElement> & { closeOnMobile?: boolean }) {
  const [closedByDefault, setClosedByDefault] = useState(false)
  useEffect(() => {
    const checkScreenSize = () => {
      closeOnMobile && setClosedByDefault(window.innerWidth < 500)
    }

    checkScreenSize()

    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }, [])

  return (
    <details
      open={!closedByDefault}
      className={clsx(classes.navColumn, className)}
      {...props}
    >
      {children}
    </details>
  )
}

function DetailsHeader({ children, ...props }: HTMLAttributes<HTMLElement>) {
  return (
    <summary {...props}>
      <h5 className={classes.groupHead}>{children}</h5>
    </summary>
  )
}

const Details = {
  Root: DetailsRoot,
  Header: DetailsHeader,
}
