import React, { RefObject, useEffect, useState } from 'react'
import { HeaderNavigation } from './HeaderNavigation'
import { HeaderActions } from './HeaderActions'
import useResizeObserver from 'hooks/useResizeObserver'

export const HeaderMobileMenu = ({
  headerRef,
}: {
  headerRef: RefObject<HTMLDivElement>
}) => {
  const [positionTop, setPositionTop] = useState(0)

  const updatePosition = () => {
    setPositionTop(headerRef?.current?.getBoundingClientRect().bottom ?? 0)
  }

  useEffect(updatePosition, [])
  useResizeObserver(headerRef?.current, updatePosition)

  const mobileMenuDynamicStyle = {
    top: positionTop,
    height: `calc(100% - ${positionTop}px)`,
  }

  return (
    <div className='header__mobile-menu' style={mobileMenuDynamicStyle}>
      <HeaderNavigation />
      <HeaderActions isMobileMenu />
    </div>
  )
}
