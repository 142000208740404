import { BookCalendlyButton, Link } from 'routes/components/Shared'

export const HeaderActions = ({ isMobileMenu }) => {
  return (
    <div className='header__actions'>
      <BookCalendlyButton
        className='header__book-button'
        category={isMobileMenu ? 'secondary' : 'primary'}
      >
        Schedule Demo
      </BookCalendlyButton>
      <Link
        className='header__log-button'
        type={isMobileMenu && 'secondary'}
        href='https://console.integration.app/login'
        target='_blank'
      >
        Log In
      </Link>

      <Link
        className='header__sign-button'
        type={isMobileMenu ? 'primary' : 'secondary'}
        href='https://console.integration.app/login'
        target='_blank'
      >
        Sign Up
      </Link>
    </div>
  )
}
