import { useMemo, useState, createContext, useContext, useEffect } from 'react'

const viewportContext = createContext({})

export const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const value = useMemo(() => ({ width, height }), [width, height])
  return (
    <viewportContext.Provider value={value}>
      {children}
    </viewportContext.Provider>
  )
}

export const useViewport = () => {
  const { width, height } = useContext(viewportContext)
  const isMobile = width < 768
  const isMediumMobile = width < 576
  const isTablet = width < 992
  return { width, height, isMobile, isMediumMobile, isTablet }
}
