import { AppProps } from 'next/app'

import * as Sentry from '@sentry/react'

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { HeadMetatags, TrackingContextProvider } from 'routes/components/Layout'
import { ErrorBoundary } from 'react-error-boundary'

import 'styles/globals.css'
import 'styles/website.css'
import 'styles-scss/index.scss'

if (process.env.NEXT_PUBLIC_ENABLE_TRACKING) {
  Sentry.init({
    dsn: 'https://ab68abc0f6663746bafb1e2b1d82e33c@o956397.ingest.sentry.io/4506388004864000',
  })
}

export default function IntegrationConsoleApp({
  Component,
  pageProps,
}: AppProps) {
  return (
    <>
      <TrackingContextProvider>
        <HeadMetatags
          title='Integration Infrastructure for SaaS Products'
          description={
            'Connect to any software your customers use. Build fully native UX. Solve your integrations now and for the years to come.'
          }
        />
        <ErrorBoundary fallback={<AppErrorFallback />} onError={logError}>
          {process.env.NEXT_PUBLIC_ENABLE_TRACKING ? (
            <GTMProvider
              state={{
                id: 'GTM-P9XKTRTC',
              }}
            >
              <Component {...pageProps} />
            </GTMProvider>
          ) : (
            <Component {...pageProps} />
          )}
        </ErrorBoundary>
      </TrackingContextProvider>
    </>
  )
}

function AppErrorFallback() {
  return (
    <div className={'global-error-boundary'}>
      <h2>Something went wrong with the application</h2>
      <button
        className={'btn btn--primary'}
        onClick={() => window.location.reload()}
      >
        Refresh Application
      </button>
    </div>
  )
}

function logError(error: Error, errorInfo: { componentStack: string }) {
  if (process.env.NEXT_PUBLIC_ENABLE_TRACKING) {
    Sentry.captureException(error, {
      extra: {
        componentStack: errorInfo.componentStack,
        error: error.toString(),
      },
    })
  }
  if (process.env.NODE_ENV === 'development') {
    console.error('App Error:', error, errorInfo)
  }
}
