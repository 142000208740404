import { ViewportProvider } from 'hooks'
import { PropsWithChildren } from 'react'
import { Provider as ReactWrapProvider } from 'react-wrap-balancer'
import { Footer, Header } from 'routes/components/Layout'
import { BookCalendly, BookCalendlyProvider } from 'routes/components/Shared'

export type DefaultPageLayoutProps = {
  className?: string
  hideAdvertiseBlock?: boolean
}

export function DefaultPageLayout({
  className,
  hideAdvertiseBlock,
  children,
}: PropsWithChildren<DefaultPageLayoutProps>) {
  return (
    <ViewportProvider>
      <ReactWrapProvider>
        <BookCalendlyProvider>
          <Header />
          <main className={className}>{children}</main>
          <Footer hideAdvertiseBlock={hideAdvertiseBlock} />
          <BookCalendly />
        </BookCalendlyProvider>
      </ReactWrapProvider>
    </ViewportProvider>
  )
}
